<template>
    <div class="poster">
        <div
            style="height: 65px;line-height: 65px;min-height: 0"
            class="warp-padding flex"
        >
            <div>
                <router-link to="/PosterList">
                    <a-icon class="return" type="left"/>&nbsp;&nbsp;
                </router-link>
                <a-input
                    placeholder="请输入"
                    v-model="Name"
                    style="width: 162px;margin-right: 16px"
                />
                <a-select style="width: 162px;" v-model="Category" placeholder="请选择">
                    <a-select-option
                        v-if="categoryList"
                        v-for="item in categoryList"
                        :value="item.Id"
                    >{{ item.title }}
                    </a-select-option
                    >
                    <!--<a-select-option value="1">人事</a-select-option>-->
                    <!--<a-select-option value="2" >合同</a-select-option>-->
                    <!--<a-select-option value="3">证书</a-select-option>-->
                    <!--<a-select-option value="4">其它</a-select-option>-->
                </a-select>
            </div>

            <div>
                <a-button class="release" @click="preview">预览</a-button>
                <a-button type="primary" @click="SaveHoliday" class="release"
                >保存
                </a-button
                >
            </div>
        </div>
        <a-row>
            <!--参数-->
            <a-col :span="2">
                <!--<a-button class="explain">替换背景图<a-icon class="return" type="right" /></a-button>-->

                <a-button class="upload">
                    替换背景图
                    <input
                        id="upload"
                        accept=".jpg,.png,.gif,.jpeg"
                        type="file"
                        @change="_upload($event, 'upload')"
                    />
                </a-button>
                <div class="warp-padding" style="padding: 0;min-height: 860px">
                    <a-menu
                        mode="inline"
                        :openKeys="openKeys"
                        @openChange="onOpenChange"
                        style="width:100%;border: 0;"
                    >
                        <a-sub-menu key="sub1" @click="select">
                            <span slot="title"><span>员工信息</span></span>
                            <a-menu-item value="员工姓名" key="Name">员工姓名</a-menu-item>
                            <a-menu-item value="员工性别" key="Sex">员工性别</a-menu-item>
                            <a-menu-item value="员工号" key="Num">员工号</a-menu-item>
                            <a-menu-item value="所属部门" key="ParentName"
                            >所属部门
                            </a-menu-item
                            >
                            <a-menu-item value="日期" key="Date">日期</a-menu-item>
                        </a-sub-menu>
                        <!--<a-sub-menu key="sub2">-->
                        <!--<span slot="title"><span>证书信息</span></span>-->

                        <!--</a-sub-menu>-->
                        <!--<a-sub-menu key="sub4">-->
                        <!--<span slot="title"><span>合同信息</span></span>-->
                        <!--</a-sub-menu>-->
                    </a-menu>
                </div>
            </a-col>
            <!--画图-->
            <a-col :span="15" :offset="1">
                <div class="warp-padding flex" style="align-items: flex-start;">
                    <div>
                        <div
                            v-for="(item, index) in dataText"
                            @click="changeKey(index)"
                            class="text-item"
                            :class="TextKey == index ? 'text-bth' : ''"
                        >
                            <span>{{ item.value }}</span>
                            <span
                                v-if="index == TextKey"
                                @click="delText(index)"
                                class="close-bth"
                            >X</span
                            >
                        </div>
                    </div>
                    <div class="images" v-viewer>
                        <canvas style="opacity: 0;" id="canvas"></canvas>
                        <img id="images" :src="fileUrl" alt=""/>
                    </div>
                </div>
            </a-col>
            <!--配置信息-->
            <a-col :span="5" :offset="1">
                <div class="warp-padding">
                    <h1 class="title">位置</h1>
                    <div class="flex_inline form-item">
                        <div style="margin-right: 8px">
                            <label class="label-text">X</label>
                            <a-input-number :value="Xlong" :min="0" @change="onChange"/>
                        </div>
                        <!-- :formatter="value => `${value}px`"
                                                    :parser="value => value.replace('px', '')"-->
                        <div style="margin-right: 8px">
                            <label class="label-text">Y</label>
                            <a-input-number :value="Ylong" :min="0" @change="Ychange"/>
                        </div>
                        <!--:formatter="value => `${value}px`"-->
                        <!--:parser="value => value.replace('px', '')"-->
                        <!--:value="dataText[TextKey].Ylong"-->
                        <div>
                            <label class="label-text">旋转角度</label>
                            <a-input-number :value="rotate" :min="0" @change="_rotate"/>
                        </div>
                        <!--:value="dataText[TextKey].rotate"-->
                    </div>
                    <div class="form-item">
                        <a-select
                            style="width: 100%;display: block;"
                            @change="_font"
                            :defaultValue="font"
                        >
                            <a-select-option v-for="fontItem in fontArr" :value="fontItem">{{fontItem}}</a-select-option>

                        </a-select>
                    </div>
                    <!--:defaultValue="dataText[TextKey].font"-->
                    <div class="form-item">
                        <a-select
                            style="width: 100%;display: block;"
                            @change="_fontSize"
                            :defaultValue="fontSize"
                        >
                            <a-select-option v-for="item in Pxnum" :value="item"
                            >{{ item }}px
                            </a-select-option
                            >
                        </a-select>
                    </div>
                    <!--:defaultValue="dataText[TextKey].fontSize"-->
                    <div class="form-item">
                        <div style="position: relative">
                            <div class="color_con" :style="{ background: color }"></div>
                            <div style="overflow: hidden">
                                <sketch-picker
                                    v-model="colors"
                                    @input="updateValue"
                                ></sketch-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'

Vue.use(Viewer)
import {Sketch} from 'vue-color'

export default {
    name: 'poster',
    data() {
        return {
            bigImgPath:
                'https://smart-resource.sikegroup.com/Ft27Dv0Fe8tOpFQU4Suq11mZ_3s0',
            fileUrl:
                'https://smart-resource.sikegroup.com/Ft27Dv0Fe8tOpFQU4Suq11mZ_3s0',
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            BigWidth: 1080,
            BigHeight: 1920,
            proX: '',
            proY: '',
            Xlong: 200,
            Ylong: 200,
            fontSize: 28,
            Pxnum: [
                12,
                14,
                16,
                18,
                20,
                22,
                24,
                26,
                28,
                30,
                32,
                34,
                36,
                38,
                40,
                42,
                44,
                46,
                48,
                50,
                52,
                54,
                56,
                58,
                60,
                62,
                64,
                66,
                68,
                70,
                72,
                74,
                76,
                78,
                80,
                82,
                84,
                86,
                88,
                90,
                92,
                94,
                96,
                98,
                100
            ],
            colors: {
                hex: '#194d33',
                hsl: {h: 150, s: 0.5, l: 0.2, a: 1},
                hsv: {h: 150, s: 0.66, v: 0.3, a: 1},
                rgba: {r: 25, g: 77, b: 51, a: 1},
                a: 1
            },
            color: '#ff0000',
            rgb: {r: '244', g: '67', b: '54'},
            rotate: 0,
            font: 'SourceHanSansCN-Bold',
            rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
            openKeys: ['sub1'],
            Text: '',
            dataText: [
                {
                    Xlong: '200',
                    Ylong: '200',
                    fontSize: '28',
                    rotate: '0',
                    color: '#ff0000',
                    text: '',
                    font: 'SourceHanSansCN-Bold',
                    value: '员工姓名',
                    rgb: {a: '1', r: '244', g: '67', b: '54'},
                    Field: 'Name'
                }
            ],
            TextKey: 0,
            dataImg: [
                {
                    url:
                        'https://smart-resource.sikegroup.com/Ft27Dv0Fe8tOpFQU4Suq11mZ_3s0',
                    Xlong: '0',
                    Ylong: '0',
                    width: '1080',
                    height: '1920'
                }
            ],
            Id: this.$route.query.id,
            Category: '',
            Name: '',
            BgUrl: '',
            categoryList: '',
            fontArr:['SourceHanSerifCN-Bold','SourceHanSansCN-Light','SourceHanSansCN-Medium','SourceHanSansCN-Regular','','SourceHanSansCN-Heavy','SourceHanSansCN-Bold','SourceHanSansCN-ExtraLight','SourceHanSansCN-Normal'],
        }
    },
    components: {
        'sketch-picker': Sketch
    },
    created() {
        this.dataText[0].text = this.userinfo.name
        this.LoadData()
        this._categoryList()
    },
    // mounted(){
    //     this.draw();
    // },
    methods: {
        LoadData() {
            let self = this
            this.$axios.get(
                1057,
                {
                    Id: self.Id,
                    uid: self.userinfo.uid
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        self.dataText = JSON.parse(res.data.message.DataText)
                        self.dataImg = JSON.parse(res.data.message.DataImg)
                        self.BigWidth = self.dataImg[0].width
                        self.BigHeight = self.dataImg[0].height
                        self.BgUrl = self.dataImg[0].url
                        self.fileUrl = self.dataImg[0].url
                        self.Category = res.data.message.Category + ''
                        self.Name = res.data.message.Name
                        self.fontSize = self.dataText[0].fontSize
                        self.Xlong = self.dataText[0].Xlong
                        self.Ylong = self.dataText[0].Ylong

                        self.draw()
                    }
                }
            )
        },
        select(item) {
            if (item.key) {
                let self = this
                let arr = {
                    Xlong: '200',
                    Ylong: '200',
                    fontSize: '28',
                    rotate: '0',
                    color: '#ff0000',
                    font: 'SourceHanSansCN-Bold',
                    rgb: {a: '1', r: '244', g: '67', b: '54'}
                }
                let imgarr = {Xlong: '100', Ylong: '100', width: '200', height: '200'}
                this.$axios.get(
                    1053,
                    {
                        uid: self.userinfo.uid,
                        Field: item.key
                    },
                    res => {
                        if (res.data.code == 1) {
                            if (item.key != 'Avatar') {
                                self.Text = res.data.message
                                arr.text = res.data.message
                                arr.value = item.item.value
                                arr.Field = item.key
                                self.dataText.push(arr)
                            } else {
                                imgarr.url = res.data.message
                                self.dataImg.push(imgarr)
                            }
                            self.draw()
                        } else {
                            self.$message.error(res.data.message)
                        }
                    }
                )
            }
        },
        SaveHoliday() {
            let self = this
            this.$axios.post(
                1055,
                {
                    Name: self.Name,
                    Category: self.Category,
                    BgUrl: self.BgUrl,
                    Id: self.Id,
                    uid: self.userinfo.uid,
                    UserName: self.userinfo.name,
                    DataImg: JSON.stringify(self.dataImg),
                    DataText: JSON.stringify(self.dataText)
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.$message.success(res.data.message)
                        this.$router.push({path: '/PosterList'})
                    } else {
                        this.$message.error(res.data.message)
                    }
                }
            )
        },
        changeKey(i) {
            this.TextKey = i
            this.fontSize = this.dataText[i].fontSize
            this.Xlong = this.dataText[i].Xlong
            this.Ylong = this.dataText[i].Ylong
            this.rotate = this.dataText[i].rotate
            this.color = this.dataText[i].color
            this.font = this.dataText[i].font
        },
        delText(i) {
            if (this.dataText.length == 1) {
                this.$message.error('必须要有一个选项')
                return false
            }
            this.TextKey = 0
            this.dataText.splice(i, 1)
            this.draw()
        },
        onChange(value) {
            this.dataText[this.TextKey].Xlong = value
            this.Xlong = value
            this.draw()
        },
        Ychange(value) {
            this.dataText[this.TextKey].Ylong = value
            this.Ylong = value
            this.draw()
        },
        _fontSize(value) {
            this.dataText[this.TextKey].fontSize = value
            this.fontSize = value
            this.draw()
        },
        _rotate(value) {
            this.dataText[this.TextKey].rotate = value
            this.rotate = value
            this.draw()
        },
        updateValue(val) {
            this.dataText[this.TextKey].color = val.hex
            this.dataText[this.TextKey].rgb = val.rgba
            this.color = val.hex
            this.draw()
        },
        _font(value) {
            this.dataText[this.TextKey].font = value
            this.font = value
            this.draw()
        },
        draw() {
            var base64 = []
            var data = this.dataImg
            var c = document.getElementById('canvas')
            var ctx = c.getContext('2d')
            var len = data.length
            var self = this
            c.width = this.BigWidth
            c.height = this.BigHeight
            ctx.rect(0, 0, c.width, c.height)
            ctx.fill()

            function drawing(n) {
                if (n < len) {
                    var img = new Image()
                    img.crossOrigin = 'Anonymous' //解决跨域
                    img.src = data[n].url
                    img.onload = function () {
                        ctx.drawImage(
                            img,
                            data[n].Xlong,
                            data[n].Ylong,
                            data[n].width,
                            data[n].height
                        )
                        //文字
                        if (n == len - 1) {
                            for (var i = 0; i < self.dataText.length; i++) {
                                ctx.save() //用来保存Canvas的状态。save之后，可以调用Canvas的平移、放缩、旋转、错切、裁剪等操作
                                ctx.fillStyle = self.dataText[i].color
                                ctx.font =
                                    self.dataText[i].fontSize +
                                    'px ' +
                                    self.dataText[i].font +
                                    ' nomal'
                                ctx.translate(self.dataText[i].Xlong, self.dataText[i].Ylong) //平移到形状中心
                                ctx.rotate((-self.dataText[i].rotate * Math.PI) / 180)
                                ctx.translate(-self.dataText[i].Xlong, -self.dataText[i].Ylong) //将中心转换回
                                ctx.fillText(
                                    self.dataText[i].text,
                                    self.dataText[i].Xlong,
                                    self.dataText[i].Ylong
                                )
                                ctx.restore() //用来恢复Canvas之前保存的状态。防止save后对Canvas执行的操作对后续的绘制有影响。
                            }
                        }
                        drawing(n + 1) //递归
                    }
                } else {
                    //保存生成作品图片
                    base64.push(c.toDataURL('image/jpeg', 0.8))
                    self.fileUrl = base64[0]
                }
            }

            drawing(0)
        },
        preview() {
            let self = this
            var e = document.createEvent('MouseEvents') //这里的clickME可以换成你想触发行为的DOM结点
            e.initEvent('click', true, true) //这里的click可以换成你想触发的行为
            this.$axios.post(
                1052,
                {
                    dataText: JSON.stringify(self.dataText),
                    dataImg: JSON.stringify(self.dataImg)
                },
                res => {
                    if (res.data.code == 1) {
                        self.fileUrl = res.data.message
                        setTimeout(function () {
                            document.getElementById('images').dispatchEvent(e)
                        }, 500)
                    } else {
                        self.$message.error(res.data.message)
                    }
                }
            )
        },
        _upload(e, name) {
            let self = this
            let files = document.getElementById(name).files
            let type = files[0].type
            var img = new Image()
            let width = ''
            let height = ''
            if (type.indexOf('image/') != 0) {
                this.$message.error('只允许上传jpg、gif、png、jpeg格式的图片')
                return false
            }
            this.__uploadFile__(files).then(res => {
                console.log(res)
                if (res) {
                    self.BgUrl = res[0].FileUrl
                    self.fileUrl = res[0].FileUrl
                    self.$set(self.dataImg[0], 'url', res[0].FileUrl)
                    img.src = res[0].FileUrl
                    img.onload = function () {
                        width = img.width
                        height = img.height
                        self.$set(self.dataImg[0], 'height', img.height)
                        self.$set(self.dataImg[0], 'width', img.width)
                        self.BigHeight = height
                        self.BigWidth = width
                        console.log(width)
                    }
                    setTimeout(function () {
                        console.log(self.BigWidth)
                        console.log(self.dataImg)
                        self.draw()
                    }, 200)
                }
            })
        },
        onOpenChange(openKeys) {
            const latestOpenKey = openKeys.find(
                key => this.openKeys.indexOf(key) === -1
            )
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.openKeys = openKeys
            } else {
                this.openKeys = latestOpenKey ? [latestOpenKey] : []
            }
        },
        _categoryList() {
            let self = this
            this.$axios.get(
                1200,
                {
                    label: 12345678
                },
                res => {
                    self.categoryList = res.data.data
                }
            )
        }
    }
}
</script>

<style lang="less" scoped>
.warp-padding {
    padding: 27px 23px;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
    border-radius: 4px;
    margin-bottom: 20px;
    background: #ffffff;
    position: relative;
    min-height: 930px;
}

.text-bth {
    color: #40a9ff !important;
    border-color: #40a9ff !important;
}

.text-item {
    width: 100px;
    text-align: center;
    height: 32px;
    line-height: 35px;
    border: 1px solid transparent;
    border-color: #d9d9d9;
    border-radius: 4px;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;

    .close-bth {
        display: block;
        text-align: center;
        line-height: 15px;
        position: absolute;
        right: -5px;
        top: -5px;
        width: 15px;
        height: 15px;
        background: #2994ffff;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;
        cursor: pointer;
    }
}

.upload {
    width: 100%;
    margin: auto;
    display: block;
    position: relative;
    border: none;
    color: #40a9ff;
    display: block;
    height: 50px;
    line-height: 50px;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%;
    background: #ffffff;
    cursor: pointer;

    #upload {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.release {
    width: 100px;
    margin-right: 10px;
    position: relative;
}

.color_con {
    width: 100%;
    height: 15px;
}

.images {
    width: 500px;
    /*height: 876px;*/
    margin: auto;
    position: relative;

    #canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.title {
    font-size: 12px;
    color: rgba(36, 36, 36, 1);
    font-weight: 400;
    margin-bottom: 15px;
}

.flex_inline {
    display: inline-flex;
}

.label-text {
    display: block;
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    margin-bottom: 5px;
}

.form-item {
    margin-bottom: 15px;
}
</style>
